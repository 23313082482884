



















































































































































































































































































































































import { downloadFileURL } from '@/shared/helpers'
import { ICertificate } from '@/shared/model/certificate.model'
import { IUser, User } from '@/shared/model/user.model'
import userService from '@/shared/services/userService'
import dayjs from 'dayjs'
import Vue from 'vue'
import QrcodeVue from 'qrcode.vue'

export default Vue.extend({
  components: {
    QrcodeVue,
  },
  data () {
    return {
      user: {} as IUser,
      image: null,
      certs: undefined as any[] | undefined,
      detailsExposed: false,
      eval1: false,
      eval2: false,
      eval3: false,
    }
  },
  computed: {
    currentRoute () {
      return window.location.href
    },
    headers (): any {
      return [
        {
          text: this.$t('certificate'),
          value: 'name',
        },
        {
          text: this.$t('level'),
          value: 'certificateTemplateObject.level',
        },
        {
          text: this.$t('issued-on'),
          value: 'date',
        },
        {
          text: this.$t('valid-thru'),
          value: 'dueDate',
        },
      ]
    },
  },
  created () {
    const uuid = this.$route.params.uuid

    if (uuid) {
      userService
        .getPublicProfile(uuid)
        .then((user: any) => {
          this.user = user
          if (user.evalid || user.metadata.birthday) {
            this.detailsExposed = true
          }
          if (user.certificates) {
            this.certs = user.certificates
            if (this.certs) {
              for (const cert of this.certs) {
                if (cert.certificateTemplateObject) {
                  if (
                    !this.certificateIsOverDueDate(cert.dueDate) &&
                    cert.certificateTemplateObject.level === 'EVAL-1'
                  ) {
                    this.eval1 = true
                  }
                  if (
                    !this.certificateIsOverDueDate(cert.dueDate) &&
                    cert.certificateTemplateObject.level === 'EVAL-2'
                  ) {
                    this.eval2 = true
                  }
                  if (
                    !this.certificateIsOverDueDate(cert.dueDate) &&
                    cert.certificateTemplateObject.level === 'EVAL-3'
                  ) {
                    this.eval3 = true
                  }
                }
              }
            }
          }
          if (user.metadata.showPdfCertificate) {
            this.headers.push({ text: this.$t('document'), value: 'certificates' })
          }
        })
        .catch((e) => {
          console.error('getPublicProfile() failed', e)
          this.$router.push('/not-found', () => {})
        })
    }
  },
  methods: {
    gotoprint (certificate: ICertificate) {
      if (certificate && certificate.pdfFile) {
        downloadFileURL(`/api/file/${certificate.pdfFile}`, 'Zertifikat', 'application/pdf')
      }
    },
    certificateIsOverDueDate (date: Date) {
      return dayjs(date).isBefore(new Date())
    },
  },
})
